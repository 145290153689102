import React from 'react';
import GoogleMapReact from 'google-map-react';
import Container from "react-bootstrap/Container";

const GoogleMaps = ({ markers }) => {
    const center = { lat: 45.118892669677734, lng: -72.9937515258789 };
    const renderMarkers = (map, maps) => {
        markers.forEach(({ lat, lng, title }, index) => {
            new maps.Marker({
                position: { lat, lng },
                map,
                title,
                icon: {
                    url: `https://www.gstatic.com/mapspro/images/stock/${ index === 0 ?
                        '1197-fac-headquarters' : '1465-trans-truck' }.png`,
                    scaledSize: new maps.Size(40, 40),
                },
            });
        });
    };

    return (
        <Container className="d-flex justify-content-center align-items-center p-3" style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBhNjQxGeKlMXKY-CcBQNxnAvHGHgWnkp4' }}
                defaultCenter={center}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
        </Container>
    );
};

export default GoogleMaps;
