import SchedulePerDay from "../components/SchedulePerDay";
import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

function Schedule() {
    const {t} = useTranslation();

    const is_english = i18n.language === 'en';
    const url = is_english ? "https://secure3.xpayrience.com/festival_trucknroll_en_coeur_en" : "https://secure3.xpayrience.com/festival_trucknroll_en_coeur";

    const day1 = {
        title: "schedule.day1.title",
        events: [
            "schedule.day1.events.1",
            "schedule.day1.events.2",
            {
                text: "schedule.day1.events.3", link: "https://www.youtube.com/watch?v=IlAAO1aNe9o",
                linkTexte: 'schedule.videoPromo',
            },
            {
                text: "schedule.day1.events.4", link: "https://www.youtube.com/@TheOfficialDayze/videos",
                linkTexte: 'schedule.videoPromo',
            },
        ],
        approximatif: true,
        img: {
            src : "/images/rockthiscountryband.jpg",
            alt : "Rock this country band",
        },
        img2: {
            src : "/images/picbois.jpg",
            alt : "Pic bois",
        },
        img3: {
            src : "/images/djdayze.jfif",
            alt : "DJ Dayze",
        },
        img4: {
            src : "/images/parade.png",
            alt : "Parade de camions",
        },

    };

    const day2 = {
        title: "schedule.day2.title",
        events: [
            "schedule.day2.events.1",
            "schedule.day2.events.2",
            {
                text: "schedule.day2.events.3", link: url,
                linkTexte: 'schedule.buyYourMechouiTicketsHere',
            },
            {
                text: "schedule.day2.events.4", link: "https://www.youtube.com/watch?v=XyS9yrKyXmA",
                linkTexte: 'schedule.videoPromo',
            },
            {
                text: "schedule.day2.events.5", link: "https://player.vimeo.com/video/907081480?dnt=1&app_id=122963 ",
                linkTexte: 'schedule.videoPromo',
            },
            {
                text: "schedule.day2.events.6", link: "https://www.youtube.com/watch?v=FVK2eI7GrXA",
                linkTexte: 'schedule.videoPromo',
            },
        ],
        approximatif: true,
        img: {
            src : "/images/nadiawaltz.jpg",
            alt : "Nadia Waltz",
        },
        img2: {
            src : "/images/djgrichu1.jpg",
            alt : "DJ Grichu",
        },
        img3: {
            src : "/images/tirsCamions.JPG",
            alt : "Tirs de camions",
        },
        img4: {
            src : "/images/theLux.jpg",
            alt : "The Lux",
        },
    };

    const day3 = {
        title: "schedule.day3.title",
        events: [

            "schedule.day3.events.1",
            {
                text: "schedule.day3.events.2", link: "https://ballonscaroboum.com/",
                linkTexte: 'schedule.visitPromoHere',
            },
            "schedule.day3.events.3",
            "schedule.day3.events.4",
            "schedule.day3.events.5",
            "schedule.day3.events.6",
        ],
        approximatif: true,
        img: {
            src : "/images/remisePrix.jpg",
            alt : "Don à la Fondation En Coeur",
        },
        img2: {
            src : "/images/envoleBallons.jpg",
            alt : "Envolée de ballons",
        },
        img3: {
            src : "/images/animation.jpg",
            alt : "Animation Grafiti",
        },
        img4: {
            src : "/images/concourHabilete.jpg",
            alt : "Concours d'habiletés",
        },
    };

    const weekend = {
        title: "schedule.weekend.title",
        events: [
            "schedule.weekend.events.1",
            "schedule.weekend.events.2",
            "schedule.weekend.events.3",
            "schedule.weekend.events.4",
            "schedule.weekend.events.5",
            "schedule.weekend.events.6",
        ],
        approximatif: false,
        img: {
            src : "/images/legende.jpg",
            alt : "Homme souriant",
        },
        img2: {
            src : "/images/ballons.jpg",
            alt : "Enfant tenant un ballon",
        },
    };

    return (
        <Container className="pt-3">
            <h1 className='p-3 fw-bolder'>{t("schedule.label")}</h1>
            <SchedulePerDay day={day1} />
            <SchedulePerDay day={day2} />
            <SchedulePerDay day={day3} />
            <SchedulePerDay day={weekend} />
        </Container>
    );
}

export default Schedule;
