import {Col, Row, Container} from "react-bootstrap";
import CustomImage from "./CustomImage";
import {useTranslation} from "react-i18next";
import ScheculeImage from "./ScheculeImage";
import ScheduleImagePicBois from "./ScheduleImagePicBois";

function SchedulePerDay({ day }) {
    const {t} = useTranslation();

    return(
        <Container className="pb-5">
            <Row>
                <Col md={6}>
                    <h3 className="p-3">{t(day.title) + ":"}</h3>
                    <ul className="ps-5">
                        {day.events.map((event, index) => (
                            <li className="py-2" key={index}>
                                {typeof event === 'object' && event.link ? (
                                    <div>
                                        <p className='mb-0'>{t(event.text)}</p>
                                        <a href={event.link} className="text-decoration-none text-danger">
                                            {event.src ? (
                                                <img src={event.src} alt={event.alt} />
                                            ) : (
                                                t(event.linkTexte)
                                            )}
                                        </a>
                                    </div>
                                ) : (
                                    t(event)
                                )}
                            </li>
                        ))}
                        {day.approximatif ? (
                            <li className="pt-3 list-unstyled">
                                <span className="text-danger">{t("schedule.approximatif")}</span>
                            </li>
                        ):
                            <></>
                        }
                    </ul>
                </Col>

                <Col md={6}>
                    {day.img2.alt === 'Pic bois' ? (
                        <>
                            <ScheduleImagePicBois image1={day.img} image2={day.img2} />
                            <ScheduleImagePicBois image1={day.img3} image2={day.img4} />
                        </>
                    ) : day.img3 ? (
                        <>
                            <ScheculeImage image1={day.img} image2={day.img2} />
                            <ScheculeImage image1={day.img3} image2={day.img4} />
                        </>
                    ): day.img2 ? (
                        <ScheculeImage image1={day.img} image2={day.img2} />
                    ) : (
                    <CustomImage image={day.img} handleOnClick={() => {}}/>
                )}
                </Col>
            </Row>
        </Container>
    );
}

export default SchedulePerDay;
