import {FaFacebook} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import FacebookPageWindow from "./FacebookPageWindow";

function Footer() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer py-3" style={{ backgroundColor: '#181818' }}>
            <Container>
                <Row className='justify-content-center'>
                    <Col md={4} className='text-center'>
                        <FacebookPageWindow/>
                    </Col>
                </Row>
                <Row className='text-white text-center py-3'>
                    <Col>
                        <span>&copy; {currentYear} {t("name")}</span>
                    </Col>
                    <Col>
                        <a href="https://www.facebook.com/trucknrollencoeur">
                            <FaFacebook size={30}/>
                        </a>
                    </Col>
                    <Col>
                        <span>{t("designedBy")}</span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;
