import GoogleMaps from "../components/GoogleMaps";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Indications() {
    const {t} = useTranslation();
    const markers = [
        { lat: 45.119407653808594, lng: -72.99150085449219, title: 'Entrée des visiteurs' },
        { lat: 45.1187858581543, lng: -72.99626922607422, title: 'Entrée des camions' },
    ];

    return (
        <Container className="pt-3">
            <h1 className="p-3 fw-bolder">{t("indications.label")}</h1>
            <Row>
                <Col md={8}>
                    <GoogleMaps markers={markers} />
                </Col>
                <Col md={4}>
                    <ul className="p-3">
                        <li className="py-3">
                            <img
                                src={`https://www.gstatic.com/mapspro/images/stock/1197-fac-headquarters.png`}
                                alt="Icone entrée des visiteurs"
                                width="40"
                                height="40"
                            />
                            <span className="fw-bold ps-2">{t("indications.visitorEntry") + " : "}</span>
                            16 Rue Philippe Côté, Bedford, QC J0J 1A0
                        </li>
                        <li className="py-3">
                            <img
                                src={`https://www.gstatic.com/mapspro/images/stock/1465-trans-truck.png`}
                                alt="Icone entrée des camions"
                                width="40"
                                height="40"
                            />
                            <span className="fw-bold ps-2">{t("indications.truckEntry") + " : "}</span>
                            15 Rue Marziali, Bedford, QC J0J 1A0
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Indications;
