function ScheduleImagePicBois({ image1, image2 }) {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ flex: '1' }}>
                <img src={image1.src} alt={image1.alt} style={{maxWidth: '100%', height: 'auto', width: '100%', objectFit: 'cover'}}/>
            </div>
            <div style={{ flex: '1' }}>
                <img src={image2.src} alt={image2.alt} style={{maxWidth: '100%', height: '100%', width: '100%'}}/>
            </div>
        </div>
    );
}

export default ScheduleImagePicBois;
