import {useParams} from "react-router-dom";
import {Col, Row, Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CustomImage from "../components/CustomImage";

function MoreStarKid() {
    let {kidNumber} = useParams();
    const {t} = useTranslation();

    const starKidsData = [
        {
            name: "Brandon",
            images: [
                { src: "/images/enfant1image1.jpg", alt: "Brandon" },
                { src: "/images/enfant1image2.jpg", alt: "Brandon" }
            ],
            text: [
                "home.starKids.kid1.par1",
                "home.starKids.kid1.par2",
                "home.starKids.kid1.par3",
                "home.starKids.kid1.par4",
                "home.starKids.kid1.par5",
                "home.starKids.kid1.par6",
                "home.starKids.kid1.par7",
                "home.starKids.kid1.par8",
            ],
        },
        {
            name: "Noah",
            images: [
                { src: "/images/enfant2image1.jpg", alt: "Noah" },
                { src: "/images/enfant2image2.jpg", alt: "Noah" }
            ],
            text: [
                "home.starKids.kid2.par1",
                "home.starKids.kid2.par2"
            ],
        }
    ];

    if (kidNumber <= 1) {
        kidNumber = 0;
    } else {
        kidNumber = 1;
    }

    const selectedKid = starKidsData[kidNumber];

    return (
        <Container className="pt-3">
            <h1 className="py-3 fw-bolder">{t("home.starKids.more") + selectedKid.name}</h1>
            <Row>
                <Col md={6}>
                    {selectedKid.text.map((par, index) => (
                        <p key={index} className="ml-5" style={{ whiteSpace: 'pre-wrap', tabSize: 4, textAlign: 'justify'}}>{`\t${t(par)}`}</p>
                    ))}
                </Col>
                <Col md={6}>
                    <Row>
                        {selectedKid.images.map((image, index) => (
                            <Col key={index} xs={12} className="text-center mb-3">
                                <CustomImage image={selectedKid.images[index]} />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default MoreStarKid;
