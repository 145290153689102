import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import ButtonxPayrience from "../components/ButtonxPayrience";
import {useTranslation} from "react-i18next";
import Prix from "../components/Prix";

function Fees() {
    const {t} = useTranslation();
    return (
        <Container className="pt-3">
            <h1 className="p-3 fw-bolder">{t("fees.label")}</h1>
            <Container className="pb-5">
                <Row>
                    <Col md={6} className="pb-5">
                        {/*<h3 className="p-3">{t("fees.presale.title")}</h3>*/}
                        {/*<ul className="ps-5">*/}
                        {/*    <li><span className="text-danger">{t("fees.presale.item1")}</span></li>*/}
                        {/*    <li>{t("fees.presale.item2")} <Prix p={100}/> {t("fees.entriesAndDiner")}</li>*/}
                        {/*    <li>{t("fees.presale.item3")} <Prix p={150}/> {t("fees.entriesAndDiner")}</li>*/}
                        {/*    <li>{t("fees.presale.item4")} <Prix p={100}/> {t("fees.entry")}</li>*/}
                        {/*    <li>{t("fees.presale.item5")} <Prix p={50}/> {t("fees.entry")}</li>*/}
                        {/*    <li><span className="text-danger">{t("fees.presale.item6")}</span></li>*/}
                        {/*</ul>*/}

                        <h3 className="p-3">{t("fees.regular.title")}</h3>
                        <ul className="ps-5">
                            <li>{t("fees.regular.item2")} <Prix p={100}/> {t("fees.entries")}</li>
                            <li>{t("fees.regular.item3")} <Prix p={150}/> {t("fees.entries")}</li>
                            <li>{t("fees.regular.item4")} <Prix p={125}/> {t("fees.entry")}</li>
                            <li>{t("fees.regular.item5")} <Prix p={60}/> {t("fees.entry")}</li>
                            <li><span className="text-danger">{t("fees.presale.item6")}</span></li>
                        </ul>
                        <Container className="p-2">
                            <ButtonxPayrience text={t("fees.button")}/>
                        </Container>
                    </Col>

                    <Col md={6}>
                        <h3 className="p-3">{t("fees.visitor.title")}</h3>
                        <ul className="ps-5">
                            <li>{t("fees.visitor.item1")}</li>
                            <li>{t("fees.visitor.item2")} <Prix p={40}/></li>
                            <li>{t("fees.visitor.item3")} <Prix p={20}/></li>
                            <li>{t("fees.visitor.item4")} <Prix p={20}/></li>
                            <li>{t("fees.visitor.item5")} <Prix p={10}/></li>
                            <li>{t("fees.visitor.item6")} <Prix p={30}/></li>
                            <li>{t("fees.visitor.item7")} {t("fees.under12")}<Prix p={15}/></li>
                        </ul>
                    </Col>

                    {/*<Col md={6}>*/}
                    {/*    <h3 className="p-3">{t("fees.regular.title")}</h3>*/}
                    {/*    <ul className="ps-5">*/}
                    {/*        <li><span className="text-danger">{t("fees.regular.item1")}</span></li>*/}
                    {/*        <li>{t("fees.regular.item2")} <Prix p={100}/> {t("fees.entries")}</li>*/}
                    {/*        <li>{t("fees.regular.item3")} <Prix p={150}/> {t("fees.entries")}</li>*/}
                    {/*        <li>{t("fees.regular.item4")} <Prix p={125}/> {t("fees.entry")}</li>*/}
                    {/*        <li>{t("fees.regular.item5")} <Prix p={60}/> {t("fees.entry")}</li>*/}
                    {/*    </ul>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        </Container>
    );
}

export default Fees;
