import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import SponsorCategorie from "../components/SponsorCategorie";
import {Link} from "react-router-dom";

function Sponsors() {
    const {t} = useTranslation();

    const sponsorsPrincipale = [
        {src: "/images/Sponsors/diesel_spec.jpg",           alt: 'Diesel Spec',                 link: 'https://www.dieselspec.com/'},
        {src: "/images/Sponsors/tfi.png",                   alt: 'TFI',                         link: 'https://tfiintl.com/fr/'},
        {src: "/images/Sponsors/garage_yan_francis.jpg",    alt: 'Party Garage Yan et Francis', link: 'https://m.facebook.com/events/6032601480109299/?aref=3'},
        {src: "/images/Sponsors/cascade.png",               alt: 'Cascade',                     link: 'https://www.cascades.com/fr'},
        {src: "/images/Sponsors/mecaniques_lapierre.jpg",   alt: 'Les mécaniques Lapierre',     link: 'https://www.facebook.com/Gilleslapierre332/?locale=fr_CA'},
        {src: "/images/Sponsors/contrans.jpg",              alt: 'Contrans',                    link: 'https://contranstankgroup.ca/'},
        {src: "/images/Sponsors/transdev.jpg",              alt: 'Transdev',                    link: 'https://www.transdev.com/fr/'},
        {src: "/images/Sponsors/tenco.jpg",                 alt: 'Tenco',                       link: 'https://tenco.ca/'},
        {src: "/images/Sponsors/tsq.jfif",                  alt: 'Truck Stop Quebec',           link: 'https://www.truckstopquebec.com/'},
        {src: "/images/Sponsors/wknd.png",                  alt: 'Wknd',                        link: 'https://montreal.wknd.fm/'},
    ];

    const sponsorsDiamant = [
        {src: "/images/Sponsors/bedford.jpg",               alt: 'Bedford',                     link: 'https://ville.bedford.qc.ca/'},
        {src: "/images/Sponsors/synergie_environnement.jpg",alt: 'Synergie Environnement',      link: 'https://www.synergie-environnement.com/'},
        {src: "/images/Sponsors/danatm4.jpg",               alt: 'Dana TM4',                    link: 'https://www.danatm4.com/fr/'},
        {src: "/images/Sponsors/alyak2000.jpg",             alt: 'Alyak 2000',                  link: 'https://www.alyak2000.ca/fr/'},
        {src: "/images/Sponsors/abconsultax.jpg",           alt: 'AB consultax',                link: 'https://abconsultax.ca/'},
        {src: "/images/Sponsors/domaine_de_la_chute.jpg",   alt: 'Domaine de la Chute',         link: 'https://www.parkbridge.com/fr/resort/resort-detail/domainedelachute'},
        {src: "/images/Sponsors/sherrington_transport.jpg", alt: 'Sherrington Transport',       link: 'https://www.facebook.com/SHERRINGTONTRANSPORT/'},
        {src: "/images/Sponsors/southbec_express.jpg",      alt: 'Southbec Express',            link: 'https://www.southbec.com/'},
        {src: "/images/Sponsors/belvedere.jpg",             alt: 'Belvedere',                   link: 'https://www.facebook.com/p/Le-Belvedere-100051581655486/'},
        {src: "/images/Sponsors/nopac.jpg",                 alt: 'Nopac',                       link: 'https://nopac.ca/'},
    ];

    const sponsorsPlatine = [
        {src: "/images/Sponsors/art_kontrol.jpg",           alt: 'Art Kontrol',                 link: 'https://www.facebook.com/Artkontrol/'},
        {src: "/images/Sponsors/incendie_bedford.jpg",      alt: 'Incendie Bedford',            link: 'https://www.facebook.com/pompiersbedfordfiredept/'},
        {src: "/images/Sponsors/incendie_st_armand.jpg",    alt: 'Incendie St-Armand',          link: 'https://www.facebook.com/p/Service-de-S%C3%A9curit%C3%A9-Incendie-Saint-Armand-Pike-River-100057240437727/'},
        {src: "/images/Sponsors/lavage_cfl.jpg",            alt: 'Lavage Haute-Pression CFL',   link: 'https://www.411habitation.com/nettoyage-lavage-pression/lanaudiere/terrebonne-2/nettoyage-lavage-pression-terrebonne.htm?fbclid=IwZXh0bgNhZW0CMTAAAR33waMxdMAHk3LVRwEvmCIWTtwoLP2bR2-J6oiPh9yxyOA6lSnDgT86FHQ_aem_AVU5096u4qmgXNYDBiPMVhY9NZwirbcZfSmcjuX75VlwohfjMJ9AjF6fNd_vrmjhe-JzQpapvK5u-Vs6QCavW4ZK'},
        {src: "/images/Sponsors/naya.png",                  alt: 'Naya',                        link: 'https://www.naya.com/'},
        {src: "/images/Sponsors/fmyr.jpg",                  alt: 'Les entreprises FMYR',        link: 'https://www.facebook.com/p/Les-Entreprises-FMYR-INC-100057511898326/'},
        {src: "/images/Sponsors/old_dutch.jpg",             alt: 'Old Dutch',                   link: 'https://www.olddutchfoods.ca/fr'},
        {src: "/images/Sponsors/pierre_tardif.jpg",         alt: 'Pierre Tardif',               link: 'https://www.ptardif.com/'},
        {src: "/images/Sponsors/pasquier.jpg",              alt: 'Pasquier',                    link: 'https://pasquier.qc.ca/'},
        {src: "/images/Sponsors/duhamel.jpg",               alt: 'Duhamel',                     link: 'https://scierieduhamel.com/'},
        {src: "/images/Sponsors/beauchemin.jpg",            alt: 'Beauchemin',                  link: 'https://www.mpbeauchemin.com/'},
        {src: "/images/Sponsors/gamache.jpg",               alt: 'Gamache',                     link: 'https://groupegamache.com/'},
        {src: "/images/Sponsors/laitue_st_jacques.jpg",     alt: 'Laitue St-Jacques',           link: 'https://www.facebook.com/LaitueStJacques/'},
        {src: "/images/Sponsors/pelletier_northwood.gif",   alt: 'Pelletier Northwood',         link: 'https://www.facebook.com/profile.php?id=61556540114797'},
        {src: "/images/Sponsors/gear_jammer.png",           alt: 'Gear Jammer Magazine',        link: 'https://www.gearjammermagazine.com/?fbclid=IwAR2_YwC1pS0pTAms-neMnIw2RVZBcc3pxnZ9gXFnRxa0l6NcrC5SLV627Ik'},
        {src: "/images/Sponsors/copeaux_kyling.jpg",        alt: 'Copeaux Kyling',              link: 'https://kyling.ca/'},
        {src: "/images/Sponsors/beaudoin.jpg",              alt: 'Beaudoin',                    link: 'https://jabeaudoinconstruction.ca/'},
        {src: "/images/Sponsors/excellencePeterbilt.jpg",   alt: 'Excellence Peterbilt',        link: 'https://excellence-peterbilt.com/fr/'},
        {src: "/images/Sponsors/st-germainExpress.jpg",     alt: 'St-Germain Express',          link: 'http://www.st-germainexpress.com/'},
        {src: "/images/Sponsors/rbrexpress.jpg",            alt: 'RBR Express',                 link: 'https://www.facebook.com/p/RBR-Express-inc-100083294592975/'},
        {src: "/images/Sponsors/concassagePelletier.jpg",   alt: 'Concassage Pelletier',        link: 'https://concassagepelletier.ca/'},
        {src: "/images/Sponsors/MBpdl.jpg",                 alt: 'MBpdl',                       link: 'https://www.facebook.com/MBPDLinc/'},
        {src: "/images/Sponsors/transport_alain_giroux.jpg",alt: 'Transport Alain Giroux',      link: 'https://www.trspgiroux.com/'},
        {src: "/images/Sponsors/laps.jpg",                  alt: 'L.A.P.S.',                    link: 'http://www.lapssonetmusique.com/'},
        {src: "/images/Sponsors/larj_choquette.jpg",        alt: 'Transport Larj Choquette',    link: 'https://www.facebook.com/profile.php?id=61550769418092'},
        {src: "/images/Sponsors/nb.jpg",                    alt: 'NB Inc.',                     link: 'https://www.instagram.com/nicolas.bujold/?igsh=MW45Yno2dnp4bjdzcg%3D%3D'},
        {src: "/images/Sponsors/graymont.jpg",              alt: 'Graymont',                    link: 'https://www.graymont.com/fr/installations/lime-plants/eastern-canada/lime-plant/bedford'},
        {src: "/images/Sponsors/remorquage_daniel.jpg",     alt: 'Remorquage Daniel',           link: 'https://www.facebook.com/profile.php?id=100057153862523'},
        {src: "/images/Sponsors/transportBDX.jpg",          alt: 'Transport BDX',               link: '/'},
        {src: "/images/Sponsors/pebXpress.jpg",             alt: 'PEB Xpress',                  link: '/'},
        {src: "/images/Sponsors/omya.jpg",                  alt: 'omya',                        link: 'https://www.omya.com/en'},
        {src: "/images/Sponsors/egl.jpg",                   alt: 'Entreprise G. Lavoie Inc.',   link: 'https://www.transportegl.com/entreprise.html'},
        {src: "/images/Sponsors/relaispetit.jfif",          alt: 'Relais Routier Petit',        link: 'https://relaispetit.ca/'},
        {src: "/images/Sponsors/kermesse.jfif",             alt: 'Kermesse Plus',               link: 'https://kermesseplus.com/'},
        {src: "/images/Sponsors/avenir.jpg",                alt: "L'Avenir et des Rivières",    link: 'https://www.laveniretdesrivieres.com/'},
    ];

    const sponsorsGold = [
        {src: "/images/Sponsors/garage_mecanix_tlg.jpg",    alt: 'Garage Mecanix TLG',          link: 'https://m.facebook.com/p/M%C3%A9canix-TLG-100063500409215/'},
        {src: "/images/Sponsors/robert_bernard.jpg",        alt: 'Robert Benard',               link: 'https://www.robertbernard.com/'},
        {src: "/images/Sponsors/sanivac.jpg",               alt: 'Sanivac',                     link: 'https://sanivac.ca/'},
        {src: "/images/Sponsors/vision.png",                alt: 'Vision',                      link: 'https://vision-icg.com/'},
        {src: "/images/Sponsors/remorquage_tessier.jpg",    alt: 'Remorquage Tessier',          link: 'https://www.facebook.com/remorquagetessierinc/?locale=fr_CA'},
        {src: "/images/Sponsors/chalut_2000.jpg",           alt: 'Transport Chalut 2000',       link: 'https://www.brome-missisquoi.ca/entreprises/transport-chalut-2000-inc/'},
        {src: "/images/Sponsors/pierquip.jpg",              alt: 'Pierquip',                    link: 'https://www.pierquip.com/fr/'},
        {src: "/images/Sponsors/maska.jpg",                 alt: 'Maska',                       link: 'https://www.maska.ca/'},
        {src: "/images/Sponsors/jhexpress.jpg",             alt: 'JH Express',                  link: '/'},
        {src: "/images/Sponsors/bourqueexpress.jpg",        alt: 'Bourque Express',             link: '/'},
        {src: "/images/Sponsors/transport_desroses.jpg",    alt: 'Transport Desroses',          link: '/'},
        {src: "/images/Sponsors/lavoixdelest.jfif",         alt: "La Voix de l'Est",            link: 'https://www.lavoixdelest.ca/'},
        {src: "/images/Sponsors/david_poulin.jfif",         alt: "Transport David Poulin",      link: '/'},
        {src: "/images/Sponsors/transportmenard.jfif",      alt: "Transport Ménard",            link: 'https://transportmenard.com/'},
        {src: "/images/Sponsors/sccustoms.jfif",            alt: "SC Customs",                  link: 'https://www.sccustomshop.com/'},
        {src: "/images/Sponsors/route_mappelle.jfif",       alt: "La Route m'appelle",          link: '/'},
        {src: "/images/Sponsors/transko.jfif",              alt: "Transko",                     link: '/'},
        {src: "/images/Sponsors/allaire.jfif",              alt: "Transport Allaire",           link: 'https://transportallaire.com/'},
    ];

    const sponsorsArgent = [
        {src: "/images/Sponsors/mobilicab.jpg",             alt: 'Mobilicab',                   link: 'https://mobilicab.com/'},
        {src: "/images/Sponsors/eurospa.jpg",               alt: 'Euro Spa',                    link: 'https://www.euro-spa.com/'},
        {src: "/images/Sponsors/transportO-claire.jpg",     alt: 'Transport O-claire',          link: 'https://o-claire.com/'},
        {src: "/images/Sponsors/transportJacquesAuger.jpg", alt: 'Transport Jacques Auger',     link: 'https://www.tja.ca/'},
        {src: "/images/Sponsors/jade66.jpg",                alt: 'Jade #66',                    link: '/'},
        {src: "/images/Sponsors/century21.jfif",            alt: 'Century 21/Marie-Josée Rioux',link: 'https://mariejosee-rioux.c21.ca/en/'},
        {src: "/images/Sponsors/sdrexpress.jfif",           alt: 'SDR Express',                 link: '/'},
        {src: "/images/Sponsors/ochrome_shop.png",          alt: 'O\'Chrome Shop',              link: 'https://www.facebook.com/profile.php?id=61556628082065&paipv=0&eav=AfatnJ-5JYYgMHM1F-9DYvI0HpIIIICSaq7hDw49LIcaUYBFLtU8_ZNCiRX1d0k9LEA'},
    ];

    const sponsorsBronze = [
        {src: "/images/Sponsors/sonic_filgo.jpg",           alt: 'Groupe Sonic Filgo',          link: 'https://www.filgo-sonic.com/'},
        {src: "/images/Sponsors/enigma.jpg",                alt: 'Enigma',                      link: 'https://www.monenigma.ca/'},
        {src: "/images/Sponsors/cosmodome.png",             alt: 'Cosmodone',                   link: 'https://cosmodome.org/'},
        {src: "/images/Sponsors/zoo_ecomuseum.png",         alt: 'Naya',                        link: 'https://zooecomuseum.ca/fr/'},
        {src: "/images/Sponsors/outillagerioux.jpg",        alt: 'Outillage Rioux',             link: 'https://outillagerioux.com/'},
        {src: "/images/Sponsors/transdiffDenisNoiseux.jpg", alt: 'Transdiff Denis Noiseux',     link: 'https://mrcrouville.qc.ca/repertoire/entreprise/trans-diff-denis-noiseux-inc'},
        {src: "/images/Sponsors/cylvie.jpg",                alt: 'Cylvie.esthétique',           link: 'https://www.facebook.com/profile.php?id=100075985088680'},
        {src: "/images/Sponsors/quilles-st-cristophe.jfif", alt:'Salle de Quilles Cowansville', link: 'https://www.bowlingcowansville.com/'},
        {src: "/images/Sponsors/wellington.jfif",           alt: 'Wellington Transport',        link: 'https://wellingtonmotorfreight.com/'},
        {src: "/images/Sponsors/jonathan_jodoin.jfif",      alt: 'Transport Jonathan Jodoin',   link: 'https://www.facebook.com/profile.php?id=100090824220478'},
        {src: "/images/Sponsors/jrompre.jfif",              alt: 'Transport J Rompré',          link: '/'},
    ];

    return (
        <Container className="p-2">
            <h1 className="p-3 pt-4 fw-bolder">{t("sponsors.label")}</h1>
            <Link className="text-danger p-4" to="/registration" style={{ fontSize: "1.5rem" }}>{t("sponsors.registrationLink")}</Link>
            <SponsorCategorie tierName={t("sponsors.principal")} sponsors={sponsorsPrincipale}/>
            <SponsorCategorie tierName={t("sponsors.diamond")} sponsors={sponsorsDiamant}/>
            <SponsorCategorie tierName={t("sponsors.platinum")} sponsors={sponsorsPlatine}/>
            <SponsorCategorie tierName={t("sponsors.gold")} sponsors={sponsorsGold}/>
            <SponsorCategorie tierName={t("sponsors.silver")} sponsors={sponsorsArgent}/>
            <SponsorCategorie tierName={t("sponsors.bronze")} sponsors={sponsorsBronze}/>
        </Container>
    );
}

export default Sponsors;
