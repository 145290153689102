import NavBar from "./_misc/components/NavBar";
import {Route, Routes} from "react-router-dom";
import Home from "./_misc/pages/Home";
import Header from "./_misc/components/Header";
import Footer from "./_misc/components/Footer";
import Inscription from "./_misc/pages/Inscription";
import Schedule from "./_misc/pages/Schedule";
import Fees from "./_misc/pages/Fees";
import Rules from "./_misc/pages/Rules";
import Sponsors from "./_misc/pages/Sponsors";
import ContactUs from "./_misc/pages/ContactUs";
import Indications from "./_misc/pages/Indications";
import MoreStarKid from "./_misc/pages/MoreStarKid";
import SuccessMessage from "./_misc/pages/SuccessMessage";

function App() {
  return (
    <div className="App">
        <Header/>
        <NavBar/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="registration" element={<Inscription/>} />
            <Route path="schedule" element={<Schedule/>} />
            <Route path="fees" element={<Fees/>} />
            <Route path="rules" element={<Rules/>} />
            <Route path="sponsors" element={<Sponsors/>} />
            <Route path="contactUs" element={<ContactUs/>} />
            <Route path="contactUs/success" element={<SuccessMessage/>} />
            <Route path="indications" element={<Indications/>} />
            <Route path="more/:kidNumber" element={<MoreStarKid/>} />
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
