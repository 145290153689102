import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function Rules() {
    const {t} = useTranslation();

    const liStyle = {
        marginBottom: '1rem'
    };

    return (
        <Container className="pb-5 pt-3">
            <h1 className="fw-bolder pt-3">{t("rules.title")}</h1>
            <Row>
                <Col md={6} className="pt-5">
                    <h3 className="pb-3">{t("rules.terrain.title")}</h3>
                    <ul>
                        <li style={liStyle}>{t("rules.terrain.rule1")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule2")}
                            <span className="text-danger"> {t("rules.terrain.rule2Note")}</span>
                        </li>
                        <li style={liStyle}>{t("rules.terrain.rule3")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule4")}
                            <span className="text-danger"> {t("rules.terrain.rule4Note")}</span>
                        </li>
                        <li style={liStyle}>{t("rules.terrain.rule5")}
                            <span className="text-danger"> {t("rules.terrain.rule5Note")}</span>
                        </li>
                        <li style={liStyle}>{t("rules.terrain.rule6")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule7")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule8")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule9")}</li>
                        <li style={liStyle}>{t("rules.terrain.rule10")}
                            <span className="text-danger"> {t("rules.terrain.rule10Note")}</span>
                        </li>
                        <li style={liStyle}>{t("rules.terrain.rule11")}
                            <br/><span> {t("rules.terrain.ruleShower")}
                                <ul>
                                    <li>{t("rules.terrain.showerFriday")}</li>
                                    <li>{t("rules.terrain.showerSaturday")}</li>
                                    <li>{t("rules.terrain.showerSunday")}</li>
                                </ul>
                            </span>
                        </li>
                    </ul>
                </Col>
                <Col md={6} className="pt-5">
                    <Row>
                        <Col xs={12}>
                            <h3 className="pb-3">{t("rules.truck.title")}</h3>
                            <ul>
                                <li style={liStyle}>{t("rules.truck.rule1")}</li>
                                <li style={liStyle}>{t("rules.truck.rule2")}
                                    <span className="text-danger"> {t("rules.truck.rule2Note")}</span>
                                </li>
                                <li style={liStyle}>{t("rules.truck.rule3")}</li>
                            </ul>
                        </Col>

                        <Col xs={12} className="pt-5">
                            <h3 className="pb-3">{t("rules.camping.title")}</h3>
                            <ul>
                                <li style={liStyle}>{t("rules.camping.rule1")}</li>
                                <li style={liStyle}>{t("rules.camping.rule2")}</li>
                                <li style={liStyle}>{t("rules.camping.rule3")}</li>
                                <li style={liStyle}>{t("rules.camping.rule4")}</li>
                                <li style={liStyle}>{t("rules.camping.rule5")}</li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Rules;
